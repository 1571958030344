// @ts-ignore
import a1Profile from "../assets/img/old/clients/jordan.png";
// @ts-ignore
import a2Profile from "../assets/img/old/clients/adeline.png";
// @ts-ignore
import a3Profile from "../assets/img/old/clients/lisa.png";
// @ts-ignore
import a4Profile from "../assets/img/old/clients/christie.png";

export const ClientsFeedbackData = {
  en: [
    {
    title: "“Incredible progress”",
    des: "First coaching experience for me and I must say: after only a few sessions I felt a real evolution. My coach has been exceptional and the Coachello team was always there to support me.",
    icon: a1Profile,
    userName: "Jordan Chenevier-Truchet",
    userDes: "CMO of Germinal",
  },
  {
    title: "“Measurable impact”",
    des: "Very responsive team, super convenient application, and very happy employees. At Gorgias we are data-driven and measure impact and Coachello is on top of the list form all the solutions we have seen so far.",
    icon: a2Profile,
    userName: "Adeline Bodemer",
    userDes: "Head of People Gorgias",
  },
  {
    title: "“Instant outcomes”",
    des: "Only after a few hours I felt I had grown so much in public speaking. My coach took the time to understand my needs and expectations. It was pleasure working with the whole Coachello team, which is very attentive.",
    icon: a3Profile,
    userName: "Lisa Lachkar",
    userDes: "Regional Director of Riskified",
  },
  {
    title: "“Tangible results”",
    des: "I grew both professionally and personally during the courses and even got to put it to the test whilst delivering some training to my colleagues today. I highly recommend Coachello platform.",
    icon: a4Profile,
    userName: "Christie Jones",
    userDes: "Manager at Tokyo Marine HCC",
  },
  ],
  fr: [
    {
      title: "Des progrès incroyables",
      des: "Première expérience de coaching pour moi et je dois dire : après seulement quelques séances, j'ai ressenti une réelle évolution. Mon coach a été exceptionnel et l'équipe de Coachello était toujours là pour me soutenir.",
      icon: a1Profile,
      userName: "Jordan Chenevier-Truchet",
      userDes: "Directeur marketing de Germinal",
    },
    {
      title: "Un impact mesurable",
      des: "Équipe très réactive, application super pratique et employés très satisfaits. Chez Gorgias, nous sommes axés sur les données et mesurons l'impact, et Coachello est en tête de liste parmi toutes les solutions que nous avons vues jusqu'à présent.",
      icon: a2Profile,
      userName: "Adeline Bodemer",
      userDes: "Responsable des ressources humaines chez Gorgias",
    },
    {
      title: "Des résultats instantanés",
      des: "Après seulement quelques heures, j'ai eu l'impression d'avoir beaucoup progressé en prise de parole en public. Mon coach a pris le temps de comprendre mes besoins et attentes. C'était un plaisir de travailler avec toute l'équipe de Coachello, qui est très attentive.",
      icon: a3Profile,
      userName: "Lisa Lachkar",
      userDes: "Directrice régionale de Riskified",
    },
    {
      title: "Des résultats tangibles",
      des: "J'ai progressé à la fois sur le plan professionnel et personnel au cours des sessions et j'ai même eu l'occasion de le mettre à l'épreuve en dispensant une formation à mes collègues aujourd'hui. Je recommande vivement la plateforme Coachello.",
      icon: a4Profile,
      userName: "Christie Jones",
      userDes: "Manager chez Tokyo Marine HCC",
    },
  ],
};